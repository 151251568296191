import React, { useEffect, useRef, useState } from "react";

import { Tooltip, useTheme } from "@material-ui/core";

import { Notes } from "@material-ui/icons";
import Button from "@remar/shared/dist/components/Button";

import { QuizCalculator } from "@remar/shared/dist/components/QuizCalculator";
import { SimpleModal } from "@remar/shared/dist/components/SimpleModal";
import TestResults from "@remar/shared/dist/components/TestResults";
import { WritingPad } from "@remar/shared/dist/components/WritingPad";
import { QuestionTypes } from "@remar/shared/dist/constants";
import { useCountdown } from "@remar/shared/dist/hooks/useCountdown";
import { useTimer } from "@remar/shared/dist/hooks/useTimer";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { CourseChapterSectionLesson, Lesson, Question } from "@remar/shared/dist/models";
import { UserQuestionAnswerDto } from "@remar/shared/dist/services/userQuestionAnswers/dto";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { getSanitizedHtmlText } from "@remar/shared/dist/utils/serviceUtils";
import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { Prompt } from "react-router";
import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import {
	clearNotes,
	completeCaseStudyQuestion,
	completeUserLessonAttempt,
	createUserLessonAttempt,
	createUserQuestionAttempt,
	endUserActivityTracking,
	getAttemptedCSQuestions,
	getFeedback,
	getFullState,
	getQuestionNotes,
	getQuizError,
	getQuizLessonQuestions,
	getUserLessonAttempt,
	moveGroupQuestionAnswerOption,
	selectQuestionNotes,
	setCSQuestions,
	setLessonFeedback,
	setOptionsError,
	setQuestionNotes,
	startUserActivityTracking,
	updateQuestionNotes,
	updateUserQuestionAttempt
} from "store/features/Lesson/lesson.slice";
import { ACTIVITY_TYPE } from "store/services";

import {
	BowTieQuestion,
	CaseStudyQuestion,
	ClozeDropDownQuestion,
	DragAndDropQuestion,
	DropDownTableQuestion,
	HighlightTableQuestion,
	HotspotHighlightQuestion,
	MatrixMultipleChoiceQuestion,
	MatrixSingleChoiceQuestion,
	MultipleResponseGroupQuestion
} from "modules/QuestionBank/Test";

import { TestQuizQuestionText } from "modules/QuestionBank/styles";

import GroupingQuestion from "./GroupingQuestion";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import QuizResultInfo from "./QuizResultInfo";
import SequencingQuestion from "./SequencingQuestion";
import SingleChoiceQuestion from "./SingleChoiceQuestion";

import {
	CalculatorIcon,
	CountdownIcon,
	FeedbackBtn,
	FeedbackIcon,
	NavigationBarLeftContainer,
	NavigationBarRightContainer,
	NotesBtn,
	QuizButtonsRow,
	QuizCalculatorButton,
	QuizCountdown,
	QuizCountdownText,
	QuizLessonContainer,
	QuizLessonDescription,
	QuizLessonLessonNumber,
	QuizLessonNavigationBar,
	QuizLessonTestTitle,
	QuizLessonTitle,
	QuizQuestionContainer,
	QuizQuestionContent,
	QuizQuestionTitle
} from "./style";

type StartQuizScreenProps = {
	name: string;
	description: string;
	handleStart: () => void;
};
const StartQuizScreen = ({ name, description, handleStart }: StartQuizScreenProps) => (
	<>
		<QuizLessonTitle>{name}</QuizLessonTitle>
		<QuizLessonDescription>{description}</QuizLessonDescription>
		<Button variant="filled" color="primary" onClick={handleStart}>
			Start the Quiz
		</Button>
	</>
);

type VisibleUtility = "calculator" | "notes" | "feedback" | null;

const IsSequencingOrGrouping = questionTypeId => {
	return [QuestionTypes.Grouping, QuestionTypes.Sequencing].includes(questionTypeId);
};

const getLessonTimeRemaining = (attempt, durationInMinutes) => {
	const startDateTime = new Date(attempt?.createdAt);
	const endTime = startDateTime?.getTime() + durationInMinutes * 60 * 1000;
	const currentTime = Date.now();
	return +((endTime - currentTime) / 1000).toFixed(0);
};

interface QuizLessonProps {
	lesson: Lesson;
	sectionLessonId?: number;
	nextLesson: CourseChapterSectionLesson;
	isQuizExit?: boolean;
	isTrial?: boolean;
	isCourseComplete: boolean;
	sideEffect: () => void;
	setShowCompletionCertificate: (param) => void;
	handleNextLesson: () => void;
	certificateAlreadyDisplayed: boolean;
}
const QuizLesson = ({
	lesson: { description, manualTimeLengthComponent: durationInMinutes, name },
	sectionLessonId,
	nextLesson,
	isQuizExit,
	isTrial,
	isCourseComplete,
	sideEffect,
	setShowCompletionCertificate,
	handleNextLesson,
	certificateAlreadyDisplayed
}: QuizLessonProps) => {
	const theme = useTheme<IExtendedTheme>();
	const { id: lessonId } = useParams<{ id: string; sectionId: string }>();
	const dispatch = useAppDispatch();

	const ref = useRef({});
	const csRef = useRef({});
	const [questionIndex, setQuestionIndex] = useState(0);

	const [caseStudyCurrentIndex, setCaseStudyCurrentIndex] = useState(0);
	const questions = useAppSelector(getQuizLessonQuestions);
	const [question, setQuestion] = useState<Question | null>(null);
	const [typeId, setTypeId] = useState<number | null>(null);
	const [showSyncTimeModal, setShowSyncTimeModal] = useState(false);
	const [questionId, setQuestionId] = useState<number>(0);
	const [isStarted, setIsStarted] = useState(true);
	const [userAnswers, setUserAnswers] = useState<UserQuestionAnswerDto[]>([]);
	const [showResults, setShowResults] = useState(false);
	const [resetCountdown, setResetCountdown] = useState(false);
	const [FailureModal, setFailureModal] = useState<boolean>(false);
	const quizError = useAppSelector(getQuizError); // todo: mb refactored

	const {
		quizLessonAttemptId: attemptId,
		quizLessonAttempt: lessonAttempt,
		// hasPracticeAttempt,
		quizLessonQuestions: userAttemptedQuestions,
		quizResult: results,
		quizPassed,
		isNextLoading,
		quizTestAnswersAlreadyAttempted: quizTestAnswersForRef,
		isAttemptLoading,
		isLoading,
		isLessonResultLoading,
		isLessonFinished,
		feedback: _feedback,
		feedbackAllowed: isFeedbackAllowed,
		optionsError: optionError,
		quizLessonAnswers: savedUserAnswers
	} = useAppSelector(getFullState);
	const quizResult = results;

	const attemptedCSQuestions = useAppSelector(getAttemptedCSQuestions); // todo: mb refactored
	const [timeSpentOnLatestQuestion, setTimeSpentOnLatestQuestion] = useState(0);

	const { text, questionId: notesQuestionId, id: notesId } = useAppSelector(selectQuestionNotes);
	const [isFinished, setIsFinished] = useState(isLessonFinished);
	const [notes, setNotes] = useState(text);
	const [showNotes, setShowNotes] = useState(false);
	const [visibleUtility, setVisibleUtility] = useState<VisibleUtility>(null);
	const analytics = useAnalyticsEventTracker("Lesson");

	const alertUser = e => {
		e.preventDefault();
		e.returnValue = "";
	};

	useEffect(() => {
		if (isCourseComplete && !nextLesson && !certificateAlreadyDisplayed) {
			setShowCompletionCertificate(true);
		}
	}, [certificateAlreadyDisplayed, isCourseComplete, nextLesson, setShowCompletionCertificate]);

	useEffect(() => {
		if (
			!isFinished &&
			quizTestAnswersForRef &&
			ref.current !== quizTestAnswersForRef &&
			Object.keys(quizTestAnswersForRef).length > 0
		) {
			const currentIndex = Object.keys(quizTestAnswersForRef).length - 1;
			for (let i = 0; i < questions.length; i++) {
				ref.current[questions[i].id] = quizTestAnswersForRef[questions[i].id] ?? [];
			}
			setTimeout(() => {
				setUserAnswers(
					questions && questions[currentIndex] ? quizTestAnswersForRef[questions[currentIndex].id] ?? [] : []
				);
			}, 200);
			setQuestionIndex(currentIndex);
			setQuestionId(questions[currentIndex]?.id);
			setQuestion(questions[currentIndex]);
			setTypeId(questions[currentIndex]?.typeId);
		}
	}, [quizTestAnswersForRef]);

	useEffect(() => {
		if (sectionLessonId) {
			dispatch(getUserLessonAttempt({ sectionLessonId, isCourseComplete }));
		}
		setIsFinished(false);
		setShowResults(false);

		return () => {
			setOptionsError("");
			setShowResults(false);
		};
	}, [dispatch, sectionLessonId]);

	useEffect(() => {
		if (questionId) {
			ref.current[questionId] = userAnswers;
		}
	}, [userAnswers]);

	useEffect(() => {
		if (questionId) {
			setUserAnswers(ref.current[questionId] ?? []);
		}
	}, [questionId]);

	useEffect(() => {
		if (!questions.length) return;
		setQuestionId(questions[questionIndex]?.id);
		setQuestion(questions[questionIndex]);
		setTypeId(questions[questionIndex]?.typeId);
	}, [questionIndex, questions]);

	useEffect(() => {
		if (!isFinished && !resetCountdown) {
			window.onbeforeunload = alertUser;
			return () => {
				window.onbeforeunload = () => null;
			};
		} else {
			window.onbeforeunload = () => null;
		}
	}, [isFinished, resetCountdown]);

	useEffect(() => {
		if (!quizPassed && isFinished) {
			analytics({ eventName: "failed_quiz", eventIdentifier: `${sectionLessonId}` });
		} else if (quizPassed && isFinished) {
			analytics({ eventName: "completed_test_only_lesson", eventIdentifier: `${sectionLessonId}` });
		}
	}, [quizPassed, isFinished]);

	const { seconds, startOrResetTimer, stopTimer } = useTimer();

	const onExpire = () => {
		if (getLessonTimeRemaining(lessonAttempt, durationInMinutes) <= 0) {
			lessonAttempt && lessonAttempt.inProgress && dispatch(completeUserLessonAttempt({ id: attemptId, sideEffect }));
			setIsFinished(true);
			dispatch(endUserActivityTracking());
			setShowFeedbackSuccess(false);
			setVisibleUtility(null);
			stopTimer();
		} else {
			startCountdown();
		}
	};

	const {
		secondsToDisplay,
		minutesToDisplay,
		hoursToDisplay,
		startCountdown,
		stopCountdown,
		handleReset,
		isTimeSynced
	} = useCountdown({
		totalTimeInMinutes: durationInMinutes,
		startTime: lessonAttempt?.createdAt,
		onExpire
	});

	const [feedback, setFeedback] = useState(_feedback);
	const [showFeedbackSuccess, setShowFeedbackSuccess] = useState(false);
	const [isNextDisabled, setIsNextDisabled] = useState(typeId === QuestionTypes.CaseStudy);

	useEffect(() => {
		if (!attemptId) {
			setIsStarted(false);
			setIsFinished(false);
			setQuestionIndex(0);
		} else {
			!resetCountdown && startCountdown();
		}
	}, [attemptId]);

	useEffect(() => {
		if (!isTimeSynced) {
			setShowSyncTimeModal(true);
		}
	}, [isTimeSynced]);

	useEffect(() => {
		if ((secondsToDisplay === 0 || !isTimeSynced) && lessonAttempt) {
			stopCountdown();
		}
	}, [isTimeSynced, secondsToDisplay, lessonAttempt]);

	useEffect(() => {
		if (attemptId) {
			dispatch(getFeedback(attemptId));
		}
	}, [attemptId, dispatch]);

	useEffect(() => {
		if (getLessonTimeRemaining(lessonAttempt, durationInMinutes) > 0 && isStarted && !isFinished) {
			startCountdown();
		}
	}, [lessonId, isStarted, isFinished, lessonAttempt?.createdAt]);

	useEffect(() => {
		setShowResults(false);
	}, [lessonId]);

	useEffect(() => {
		setNotes(text);
	}, [text, notesQuestionId]);

	useEffect(() => {
		setFeedback(_feedback);
	}, [text, attemptId]);

	useEffect(() => {
		if (attemptId && !isStarted) {
			if (
				getLessonTimeRemaining(lessonAttempt, durationInMinutes) < 0 ||
				lessonAttempt?.inProgress === false ||
				isLessonFinished ||
				!isTimeSynced
			) {
				setIsStarted(false);
				setIsFinished(true);
				dispatch(endUserActivityTracking());
			} else {
				setIsStarted(true);
				setIsFinished(false);
			}
		}
	}, [
		attemptId,
		isStarted,
		typeId,
		lessonAttempt?.createdAt,
		questionId,
		lessonAttempt,
		durationInMinutes,
		isLessonFinished,
		isTimeSynced
	]);

	useEffect(() => {
		if (questions.length > 0) {
			const lastQuestionId = questions[questions.length - 1].id;
			if (attemptId && isStarted && savedUserAnswers![lastQuestionId]) {
				if (lessonAttempt && lessonAttempt.inProgress && !isFinished) {
					dispatch(
						completeUserLessonAttempt({
							id: attemptId,
							sideEffect
						})
					);
				}
				setIsFinished(true);
				setShowFeedbackSuccess(false);
				setVisibleUtility(null);
				stopCountdown();
			}
		}
	}, [attemptId, isStarted, savedUserAnswers, isFinished, lessonAttempt, questions, dispatch]);

	useEffect(() => {
		if (questions.length && questionIndex >= 0) {
			const questionId = questions[questionIndex]?.id;
			questionId && dispatch(getQuestionNotes(questionId));
		}
	}, [questions, questionIndex, dispatch]);

	useEffect(() => {
		if (attemptId) {
			const questionAttemptTimeSpent =
				(savedUserAnswers && savedUserAnswers[questionId]?.timeSpent) || timeSpentOnLatestQuestion;
			startOrResetTimer(questionAttemptTimeSpent);
		}
	}, [questionIndex, savedUserAnswers, questionId, attemptId]);

	useEffect(() => {
		if (!FailureModal) {
			dispatch(setOptionsError(""));
		}
	}, [FailureModal, dispatch]);

	useEffect(() => {
		if (optionError !== "") {
			setFailureModal(true);
		}
	}, [optionError]);

	useEffect(() => {
		IsSequencingOrGrouping(question?.typeId) && setIsNextDisabled(false);
	}, [question?.typeId, question?.id]);

	useEffect(() => {
		question?.typeId !== QuestionTypes.CaseStudy &&
			!IsSequencingOrGrouping(question?.typeId) &&
			getQuestionTypeValidation(question?.typeId, question, userAnswers);
	}, [question?.typeId, userAnswers, question]);

	useEffect(() => {
		ref.current[questionId] = userAnswers;
	}, [userAnswers]);

	useEffect(() => {
		if (questionId) {
			setUserAnswers(ref.current[questionId] ?? []);
		}
	}, [questionId]);

	const handleStart = () => {
		setIsStarted(true);
		if (resetCountdown) {
			window.onbeforeunload = alertUser;
			handleReset();
			setResetCountdown(resetCountdown => !resetCountdown);
		}
		!attemptId && sectionLessonId && dispatch(createUserLessonAttempt({ sectionLessonId }));
		dispatch(startUserActivityTracking({ activityType: ACTIVITY_TYPE.testAttempt, id: +lessonId }));
		analytics({ eventName: "started_test_only_lesson", eventIdentifier: `${sectionLessonId}` });
	};

	const handleNext = () => {
		const moveToNextQuestion = () => {
			if (questionIndex < questions.length - 1) {
				setQuestionIndex(questionIndex + 1);
			}
		};

		if (userAnswers.length > 0 || question.typeId === QuestionTypes.CaseStudy) {
			if (question.typeId === QuestionTypes.MatrixSingleChoice && question.data.groups?.length !== userAnswers.length) {
				return;
			}

			dispatch(clearNotes());

			if (question.typeId === QuestionTypes.CaseStudy) {
				const { caseStudyQuestions } = question;
				const orderedQuestions = [...caseStudyQuestions!].sort((a, b) => a.order - b.order);
				const lastQuestion = orderedQuestions[caseStudyCurrentIndex];
				const csLastQuestionAnser = csRef.current[`${caseStudyCurrentIndex}-${question.id}`];
				if (attemptId && !savedUserAnswers![lastQuestion.id]) {
					setTimeSpentOnLatestQuestion(0);
					dispatch(
						createUserQuestionAttempt({
							userLessonAttemptId: attemptId,
							questionId: question.id,
							subQuestionId: lastQuestion.id,
							userAnswers: [...csLastQuestionAnser],
							timeSpent: seconds,
							caseStudySideEffect: isError => {
								if (isError) return;
								questions[questions.length - 1].id === question.id &&
									dispatch(completeCaseStudyQuestion({ questionId: question.id }));
								if (questionIndex < questions.length - 1 && !optionError) {
									setQuestionIndex(questionIndex + 1);
								}
							}
						})
					);
				} else if (attemptId) {
					dispatch(
						updateUserQuestionAttempt({
							questionUserAttemptId: savedUserAnswers![lastQuestion.id]?.id,
							userAnswers: [...csLastQuestionAnser],
							timeSpent: seconds,
							caseStudySideEffect: isError => {
								if (isError) return;
								questions[questions.length - 1].id === question.id &&
									dispatch(completeCaseStudyQuestion({ questionId: question.id }));
								if (questionIndex < questions.length - 1 && !optionError) {
									setQuestionIndex(questionIndex + 1);
								}
							}
						})
					);
				}
			} else if (attemptId && !savedUserAnswers![questionId]) {
				setTimeSpentOnLatestQuestion(0);
				dispatch(
					createUserQuestionAttempt({
						userLessonAttemptId: attemptId,
						questionId: question.id,
						userAnswers: [...userAnswers],
						timeSpent: seconds,
						sideEffect: moveToNextQuestion
					})
				);
			} else if (attemptId) {
				dispatch(
					updateUserQuestionAttempt({
						questionUserAttemptId: savedUserAnswers![questionId]?.id,
						userAnswers: [...userAnswers],
						timeSpent: seconds,
						sideEffect: moveToNextQuestion
					})
				);
			}
		}
	};

	const handlePrev = () => {
		if (savedUserAnswers![questionId]?.id) {
			dispatch(
				updateUserQuestionAttempt({
					questionUserAttemptId: savedUserAnswers![questionId]?.id,
					userAnswers: [...userAnswers],
					timeSpent: seconds,
					sideEffect: () => {
						setQuestionIndex(questionIndex - 1);
					}
				})
			);
			return;
		} else {
			setTimeSpentOnLatestQuestion(seconds);
		}
		dispatch(clearNotes());
		setQuestionIndex(questionIndex - 1);
	};

	if (isStarted && questions.length === 0 && !quizError && !isAttemptLoading) {
		return null;
	}

	const handleSaveNotes = () => {
		if (notes?.length > 0)
			if (notesQuestionId === questions[questionIndex].id && text.length > 0) {
				dispatch(updateQuestionNotes({ data: { text: notes }, filters: { id: notesId } }));
				analytics({ eventName: "took_notes_during_test_only_lesson", eventIdentifier: `${notesId}` });
			} else {
				dispatch(setQuestionNotes({ text: notes, questionId: questions[questionIndex].id, customTestId: undefined }));
			}
	};

	const handleSaveFeedback = () => {
		setShowFeedbackSuccess(true);
		dispatch(setLessonFeedback({ text: feedback, userLessonAttemptId: attemptId }));
	};

	const checkAllSelection = (question, options, customUserAnswers) => {
		const {
			data: { groups }
		} = question;
		const filteredGroups = groups.filter(group => group[options]);
		return filteredGroups.length !== customUserAnswers.length;
	};

	function getQuestionTypeValidation(id, question, customUserAnswers) {
		switch (id) {
			case QuestionTypes.ClozeDropDown:
			case QuestionTypes.RationalScoringDropDown:
			case QuestionTypes.DropDownTable:
				setIsNextDisabled(checkAllSelection(question, "answerOptions", customUserAnswers));
				break;
			case QuestionTypes.DragAndDrop:
			case QuestionTypes.RationalScoringDragAndDrop:
			case QuestionTypes.MatrixSingleChoice:
				setIsNextDisabled(checkAllSelection(question, "selectedAnswerOptions", customUserAnswers));
				break;
			case QuestionTypes.MatrixMultipleChoice: {
				const isAllColumnsSelected = question.data.groups.every(group =>
					customUserAnswers.some(answer => answer.groupId === group.id)
				);
				setIsNextDisabled(!isAllColumnsSelected);
				break;
			}
			case QuestionTypes.HotspotHighlight:
				setIsNextDisabled(customUserAnswers.length <= 0);
				break;
			case QuestionTypes.MultipleResponseGroup:
			case QuestionTypes.HighlightTable:
				const isAllSelected = question.data.groups.every(group =>
					customUserAnswers.some(answer => answer.groupId === group.id)
				);
				setIsNextDisabled(!isAllSelected);
				break;
			case QuestionTypes.BowTie:
				setIsNextDisabled(customUserAnswers.length < 5);
				break;
			default:
				setIsNextDisabled(customUserAnswers.length === 0);
				break;
		}
	}

	const getQuestionType = questionTypeId => {
		switch (questionTypeId) {
			case QuestionTypes.DropDownTable:
				return (
					<DropDownTableQuestion
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
						key={question.id}
					/>
				);
			case QuestionTypes.BowTie:
				return (
					<BowTieQuestion
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
						key={question.id}
						answersRef={ref}
					/>
				);
			case QuestionTypes.MultipleResponseGroup:
				return (
					<MultipleResponseGroupQuestion
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
						key={question.id}
					/>
				);
			case QuestionTypes.HighlightTable:
				return (
					<HighlightTableQuestion
						question={question}
						onChange={setUserAnswers}
						key={question.id}
						userAnswers={userAnswers}
					/>
				);
			case QuestionTypes.MatrixMultipleChoice:
				return (
					<MatrixMultipleChoiceQuestion
						key={question.id}
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
					/>
				);
			case QuestionTypes.DragAndDrop:
			case QuestionTypes.RationalScoringDragAndDrop:
				return (
					<DragAndDropQuestion
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
						answersRef={ref}
						key={question.id}
					/>
				);
			case QuestionTypes.HotspotHighlight:
				return (
					<HotspotHighlightQuestion
						question={question}
						onChange={setUserAnswers}
						key={question.id}
						userAnswers={userAnswers}
					/>
				);
			case QuestionTypes.ClozeDropDown:
			case QuestionTypes.RationalScoringDropDown:
				return <ClozeDropDownQuestion question={question} onChange={setUserAnswers} userAnswers={userAnswers} />;
			case QuestionTypes.CaseStudy:
				return (
					<CaseStudyQuestion
						question={question}
						key={`case-study-${questionIndex}`}
						getCSValidation={getQuestionTypeValidation}
						questionIndex={questionIndex}
						csRef={csRef}
						attemptId={attemptId}
						setCSQuestions={setCSQuestions}
						attemptedCSQuestions={attemptedCSQuestions}
						loading={isNextLoading}
						setCaseStudyCurrentIndex={setCaseStudyCurrentIndex}
					/>
				);
			case QuestionTypes.MatrixSingleChoice:
				return (
					<MatrixSingleChoiceQuestion
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
						key={question.id}
					/>
				);
			case QuestionTypes.SingleChoice:
				return <SingleChoiceQuestion question={question} onChange={setUserAnswers} userAnswers={userAnswers} />;
			case QuestionTypes.MultipleChoiceSN:
			case QuestionTypes.MultipleChoiceSATA:
			case QuestionTypes.MultipleChoice:
				return <MultipleChoiceQuestion question={question} onChange={setUserAnswers} userAnswers={userAnswers} />;
			case QuestionTypes.Grouping:
				return (
					<GroupingQuestion
						question={question}
						questionIndex={questionIndex}
						uniqueIdentifier={`${isStarted}-${attemptId}-${questionTypeId}`}
						dispatchAction={moveGroupQuestionAnswerOption}
						setUserAnswers={setUserAnswers}
						answersRef={ref}
					/>
				);
			case QuestionTypes.Sequencing:
				return (
					<SequencingQuestion
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
						answersRef={ref}
					/>
				);
			default:
				return null;
		}
	};

	const handleShowNotes = questionId => {
		if (notesQuestionId !== questionId) {
			dispatch(getQuestionNotes(questionId));
		}
		analytics({ eventName: "hit_close_extended_results", eventIdentifier: `${questionId}` });
		setShowNotes(true);
	};

	if (isLoading || isAttemptLoading || isLessonResultLoading) {
		return <ContentLoader />;
	}

	const renderQuiz = () => (
		<>
			<QuizLessonTestTitle>{name}</QuizLessonTestTitle>
			<QuizLessonNavigationBar>
				<NavigationBarLeftContainer>
					<NotesBtn onClick={() => setVisibleUtility("notes")}>
						<Notes style={{ width: "20px", height: "20px", fill: "#b6e0fe" }} />
						Notes
					</NotesBtn>
					<QuizCalculatorButton onClick={() => setVisibleUtility("calculator")}>
						<CalculatorIcon /> Calculator
					</QuizCalculatorButton>
					<FeedbackBtn onClick={() => setVisibleUtility("feedback")} disabled={!isFeedbackAllowed}>
						<Tooltip title="Feedback was already sent" disableHoverListener={!isFeedbackAllowed}>
							<>
								<FeedbackIcon /> Feedback
							</>
						</Tooltip>
					</FeedbackBtn>
				</NavigationBarLeftContainer>
				<NavigationBarRightContainer>
					<QuizLessonLessonNumber>
						{questionIndex + 1}
						<span> / {questions.length}</span>
					</QuizLessonLessonNumber>
					<QuizCountdown>
						<CountdownIcon />
						<QuizCountdownText>Time Left</QuizCountdownText> {hoursToDisplay}:
						{minutesToDisplay.toString().length < 2 ? String(minutesToDisplay).padStart(2, "0") : minutesToDisplay}:
						{String(secondsToDisplay).padStart(2, "0")}
					</QuizCountdown>
				</NavigationBarRightContainer>
			</QuizLessonNavigationBar>
			<QuizQuestionContainer>
				<QuizQuestionContent>
					<QuizQuestionTitle>Question {questionIndex + 1}</QuizQuestionTitle>
					<TestQuizQuestionText>{getSanitizedHtmlText(question?.text)}</TestQuizQuestionText>
					<TestQuizQuestionText
						color={theme.palette.colors.basic[600]}
						dangerouslySetInnerHTML={{ __html: question?.description }}
					/>
					{question && getQuestionType(question.typeId)}
				</QuizQuestionContent>
				{visibleUtility === "calculator" && (
					<QuizCalculator onClose={() => setVisibleUtility(null)} backgroundColor={"#eceff4"} />
				)}
				{visibleUtility === "notes" && (
					<WritingPad
						title="Notes"
						placeholder="Write Your Notes Here..."
						text={notes}
						onClose={() => setVisibleUtility(null)}
						onTextChange={setNotes}
						onSave={handleSaveNotes}
						saveOnBlur={true}
						backgroundColor={"#eceff4"}
					/>
				)}
				{visibleUtility === "feedback" && isFeedbackAllowed && (
					<WritingPad
						title="Feedback"
						placeholder="Write Your Feedback Here..."
						text={feedback}
						onClose={() => setVisibleUtility(null)}
						onTextChange={setFeedback}
						onSave={() => handleSaveFeedback()}
						disabled={showFeedbackSuccess}
						buttonText="Send"
						backgroundColor={"#eceff4"}
					/>
				)}
			</QuizQuestionContainer>
			<QuizButtonsRow>
				{questionIndex > 0 && (
					<Button variant="filled" color={"basic"} onClick={handlePrev}>
						Previous Question
					</Button>
				)}
				<Button
					variant="filled"
					color="control"
					loading={isNextLoading}
					onClick={handleNext}
					disabled={isNextDisabled || isNextLoading}
				>
					{questionIndex === questions.length - 1 ? "Finish" : "Next Question"}
				</Button>
			</QuizButtonsRow>
		</>
	);
	return (
		<QuizLessonContainer>
			<Prompt when={!isFinished && isQuizExit} message="A test is in progress. Are you sure you want to leave?" />
			{showResults ? (
				<TestResults
					results={quizResult}
					onClose={() => {
						analytics({ eventName: "hit_close_extended_results", eventIdentifier: lessonId });
						setShowResults(false);
						setShowNotes(false);
					}}
					onNextLesson={handleNextLesson}
					showNextLesson={!!nextLesson}
					userAttemptedQuestions={userAttemptedQuestions || []}
					handleShowNotes={handleShowNotes}
					notes={notes}
					showNotes={showNotes}
					setShowNotes={setShowNotes}
				/>
			) : !isStarted && !isFinished ? (
				<StartQuizScreen name={name} description={description} handleStart={handleStart} />
			) : quizError ? (
				quizError
			) : !isFinished ? (
				renderQuiz()
			) : (
				<QuizResultInfo
					quizResult={quizResult}
					lessonId={lessonId}
					sectionLessonId={sectionLessonId}
					nextLesson={nextLesson}
					singleQAnswerRef={ref}
					csRef={csRef}
					handleNextLesson={handleNextLesson}
					lessonAttempt={lessonAttempt}
					setQuestionIndex={setQuestionIndex}
					setQuestion={setQuestion}
					setQuestionId={setQuestionId}
					setUserAnswers={setUserAnswers}
					setResetCountdown={setResetCountdown}
					isCourseComplete={isCourseComplete}
					isTrial={isTrial}
					setShowResults={setShowResults}
					setShowCompletionCertificate={setShowCompletionCertificate}
					setTypeId={setTypeId}
				/>
			)}
			<SimpleModal
				theme={theme}
				title="Please select options again"
				open={FailureModal}
				onClose={() => setFailureModal(false)}
				text={optionError}
				footer={
					<Button variant="filled" color={"primary"} size="medium" onClick={() => setFailureModal(false)}>
						Ok
					</Button>
				}
			/>
			<SimpleModal
				title="Sync Device Time"
				open={showSyncTimeModal}
				onClose={() => setShowSyncTimeModal(false)}
				text={"Please make sure your device time is synced take tests"}
				footer={
					<Button variant="filled" color="primary" size="medium" onClick={() => setShowSyncTimeModal(false)}>
						Ok
					</Button>
				}
			/>
		</QuizLessonContainer>
	);
};

export default QuizLesson;
