import React, { useCallback, useMemo } from "react";

import { Box, List, ListItem, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Button from "@remar/shared/dist/components/Button";

import { ExternalIntegrationIds, MAX_COURSE_ADDONS } from "@remar/shared/dist/constants";
import { Course, CourseData, UserSubscriptionType } from "@remar/shared/dist/models";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";

import { useHistory } from "react-router-dom";

import { useAppDispatch } from "store";
import { changeSubscriptionType } from "store/features/Auth/authSlice";
import { UserSubscriptionTypeCategories } from "store/features/Auth/constants";

import { ReactComponent as InfoSvg } from "assets/icons/icon-info-circle.svg";
import { routes } from "core/constants";

import { FeatureName, useChangeCourseStyles } from "./styles";

interface IProps {
	course: Course;
	isSigningUp?: boolean;
	accountClaimCode?: string;
	onClose?: () => void;
	nextStep?: () => void;
	setPickCourse?: (id: number | null) => void;
}
const SelectOfferings = ({
	course,
	isSigningUp = true,
	accountClaimCode,
	onClose,
	setPickCourse,
	nextStep
}: IProps) => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const classes = useChangeCourseStyles();

	const getRecurringPrice = useCallback(course => {
		const initialOrRecurringPrice = course.allowedForUST
			?.find(type => type.isRecurring && !type.isTrial)
			?.subTypeEIDItems?.find(i => i.integrationId == ExternalIntegrationIds.Stripe && i.parentId === null);
		return formatUSD(initialOrRecurringPrice.data["price"]) || 0;
	}, []);

	const getInitialPrice = useCallback(offering => {
		const initialOfferPrice = offering.subTypeEIDItems?.find(
			i => i.integrationId == ExternalIntegrationIds.Stripe && i.parentId === null
		);
		return formatUSD(initialOfferPrice.data["price"]) || 0;
	}, []);

	const getDuration = useCallback(offer => `${offer?.duration.months * 30}-Day Access`, []);

	const getTrialSubscriptionTypeId = useCallback(course => {
		const allowedUST = course.allowedForUST?.find(
			type =>
				!type.isRecurring &&
				type.isTrial &&
				type.userSubscriptionTypeCategoryId == UserSubscriptionTypeCategories.Course
		);
		return allowedUST?.id;
	}, []);

	const { data, hasTrial } = useMemo(() => {
		const _hasTrial = !!(course.allowedForUST || []).find(st => st.isTrial);
		return { data: course?.data as CourseData, hasTrial: _hasTrial };
	}, [course]);

	const availableOfferings = useMemo(
		() =>
			((course.allowedForUST || []) as UserSubscriptionType[])
				.filter(
					ust =>
						ust.isActive &&
						!ust.isRecurring &&
						!ust.isTrial &&
						ust.userSubscriptionTypeCategoryId == UserSubscriptionTypeCategories.Course
				)
				.sort((a, b) => {
					if (a.duration && b.duration) {
						return a.duration.months! - b.duration.months!;
					} else {
						return 0;
					}
				}),
		[course]
	);

	return (
		<>
			<Box className={classes.coursesContainer}>
				{availableOfferings.reduce((acc: React.ReactNode[], offer) => {
					if (!offer.isAppleSubscription) {
						acc.push(
							<Box className={`${classes.course} ${offer.isDefault ? classes.defaultOffer : ""}`} key={course.id}>
								<Box ml="2%" height="100%" width="100%" display="flex" flexDirection="column">
									<Typography className={classes.changeCourseName}>
										{course.name !== " " ? course.name : "N/A"}
									</Typography>
									<Typography className={classes.daysAccess}>{getDuration(offer)}</Typography>

									<Typography className={classes.changeCourseAmount} variant="h6">
										{getInitialPrice(offer)}
									</Typography>
									<List className={classes.changeCourseList}>
										{data?.marketingPoints.slice(0, MAX_COURSE_ADDONS).map((marketingPoint, i) => (
											<ListItem className={classes.changeCourseListItem} key={i}>
												<CheckCircleIcon fontSize="small" className={classes.changeCourseTick} />
												<FeatureName title={marketingPoint}> {marketingPoint} </FeatureName>
											</ListItem>
										))}
									</List>

									<Box display={"flex"} justifyContent={"center"}>
										<Button
											variant="filled"
											color="primary"
											size="small"
											className={classes.selectCourse}
											onClick={() => {
												dispatch(changeSubscriptionType(offer.id));
												if (!isSigningUp && nextStep) {
													nextStep();
													return;
												}
												if (accountClaimCode) {
													onClose && onClose();
													history.push(
														`${routes.signUp.getPath()}/course/${course.id}/claimAccount/${accountClaimCode}`
													);
												} else if (!!sessionStorage.getItem("platform")) {
													onClose && onClose();
													history.push(`${routes.signUp.getPath()}/oauth/course/${course.id}`);
												} else {
													onClose && onClose();
													history.push(`${routes.signUp.getPath()}/course/${course.id}/${offer.id}`);
												}
											}}
										>
											Buy Now
										</Button>
									</Box>
									<Box display="flex" justifyContent="flex-start">
										<InfoSvg className={classes.changeCourseInfo} />
										<span className={classes.changeCourseListItem}>
											{data?.additionalInformation ??
												`After the first payment will be ${getRecurringPrice(course)}/month renewal.`}
										</span>
									</Box>
								</Box>
							</Box>
						);
					}
					return acc;
				}, [])}
			</Box>
			{isSigningUp && (
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={"center"}
					className={classes.freeTrialWrapper}
					mt={2}
				>
					<Button color="basic" variant="outlined" onClick={() => setPickCourse && setPickCourse(null)}>
						Back
					</Button>
					{hasTrial && !accountClaimCode && (
						<Typography
							onClick={() => {
								dispatch(changeSubscriptionType(getTrialSubscriptionTypeId(course)));
								onClose && onClose();
								!!sessionStorage.getItem("platform")
									? history.push(`${routes.signUp.getPath()}/oauth/course/${course.id}/trial`)
									: history.push(`${routes.signUp.getPath()}/course/${course.id}/trial`);
							}}
						>
							<span>Not sure?</span> Start Free Trial
						</Typography>
					)}
				</Box>
			)}
		</>
	);
};

export default SelectOfferings;
