import React, { useEffect, useState } from "react";

import { Box, Grid, Typography } from "@material-ui/core";

import SvgIcon from "@material-ui/core/SvgIcon";

import { ReactComponent as CheckBoxSvg } from "@remar/shared/dist/assets/icons/checkbox.svg";
import { ReactComponent as IconEyeSlash } from "@remar/shared/dist/assets/icons/icon-eye-slash.svg";
import { ReactComponent as IconEye } from "@remar/shared/dist/assets/icons/icon-eye.svg";
import Button from "@remar/shared/dist/components/Button";
import { TextField } from "@remar/shared/dist/components/TextField";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { passwordREGEX } from "@remar/shared/dist/utils/serviceUtils";
import { Field, Form, Formik } from "formik";

import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { Link, useHistory, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { clearPasswordReset, forgotVerification, resetPassword, selectAuth } from "store/features/Auth/authSlice";
import * as Yup from "yup";

import { routes } from "core/constants";

import EntryScreenLayout from "./EntryScreenLayout";
import PasswordMatchBox from "./components/PasswordMatchBox";
import { passwordValidationMessage } from "./components/schemas";

import { useStyles } from "./resetPassword.styles";

import { useValidatePassword } from "../../hooks/useValidatePassword";

const validationSchema = Yup.object().shape({
	password: Yup.string().required("Required").matches(passwordREGEX, passwordValidationMessage),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref("password"), null], "Passwords must match")
		.required("Required")
});

const ResetPasswordForm = ({ handleSubmit, errorMessage, isLoading }) => {
	const [showPass, setShowPass] = useState(false);
	const { validatePassword, showPasswordErrorBox, passwordErrorBoxState } = useValidatePassword();

	return (
		<Formik
			initialValues={{
				password: "",
				confirmPassword: ""
			}}
			validationSchema={validationSchema}
			onSubmit={values => handleSubmit(values)}
		>
			{({ isValid, values }) => (
				<>
					{errorMessage && (
						<Box mt={2}>
							<Typography variant="caption" style={{ color: "red" }}>
								{errorMessage}
							</Typography>
						</Box>
					)}
					<Form>
						<Box mt={3}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography variant="subtitle1">Create a new password that is at least 8 characters long.</Typography>
									<Box style={{ position: "relative", paddingTop: "12px" }}>
										<Field
											component={TextField}
											type={showPass ? "text" : "password"}
											name="password"
											placeholder="New Password"
											InputProps={{
												endAdornment: (
													<SvgIcon fontSize="small" cursor="pointer" onClick={() => setShowPass(!showPass)}>
														{showPass ? <IconEye /> : <IconEyeSlash />}
													</SvgIcon>
												),
												disableUnderline: true
											}}
											fullWidth
											validate={validatePassword}
										/>
										{showPasswordErrorBox && <PasswordMatchBox passwordErrorBox={passwordErrorBoxState} />}
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Field
										component={TextField}
										type={showPass ? "text" : "password"}
										name="confirmPassword"
										placeholder="Confirm New Password"
										InputProps={{ disableUnderline: true }}
										fullWidth
									/>
								</Grid>
							</Grid>
						</Box>
					</Form>
					<Box mt={1}>
						<Button
							fullWidth
							color="primary"
							disabled={!isValid || isLoading}
							loading={isLoading}
							variant="filled"
							onClick={() => handleSubmit(values)}
						>
							Save The New Password
						</Button>
					</Box>
				</>
			)}
		</Formik>
	);
};

const ResetPassword = () => {
	const classes = useStyles();
	const history = useHistory();
	const { code } = useParams<{ code: string }>();
	const dispatch = useAppDispatch();
	const analytics = useAnalyticsEventTracker("User");

	const { errorMessage, isLoading, passwordReset, passwordResetEmail } = useAppSelector(selectAuth);

	const successSideEffect = code => {
		analytics({ contentIdentifier: `${code}`, eventName: "password-reset" });
	};

	const handleSubmit = values => {
		dispatch(resetPassword({ code, password: values.password, successSideEffect }));
	};

	useEffect(() => {
		dispatch(forgotVerification({ code, sideEffect: () => console.log(history) }));
	}, [code, dispatch, history]);
	useEffect(
		() => () => {
			dispatch(clearPasswordReset());
		},
		[dispatch]
	);

	const handleRoute = e => {
		e.preventDefault();
		history.replace(routes.signIn.getPath());
	};

	return isLoading ? (
		<ContentLoader />
	) : passwordReset ? (
		<EntryScreenLayout title="Password changed">
			<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
				<Box mb={5}>
					<Typography className={classes.description}>Your password has been successfully changed.</Typography>
					<Typography className={classes.description}>
						A confirmation email has been sent to <b style={{ marginLeft: "10px" }}>{`[${passwordResetEmail}]`}</b>
					</Typography>
				</Box>
				<Box display="flex" flexDirection="column" alignItems="center">
					<CheckBoxSvg className={classes.checkbox} />
					<Link to={"#"} onClick={handleRoute} className={classes.linkText}>
						Return to Log In
					</Link>
				</Box>
			</Box>
		</EntryScreenLayout>
	) : (
		<EntryScreenLayout title="Set New Password">
			<ResetPasswordForm handleSubmit={handleSubmit} isLoading={isLoading} errorMessage={errorMessage} />
			<Box mt={4} textAlign="center">
				<Link to={routes.signIn.getPath()} className={classes.linkText}>
					Return to Log In
				</Link>
			</Box>
		</EntryScreenLayout>
	);
};

export default ResetPassword;
