import React, { useMemo, useState } from "react";

import { Box, IconButton, Link, Modal, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useUpgradeForm } from "hooks/useUpgradeForm";

import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { getUserData, selectInactiveSubscription, selectIsTrial, setShowBanner } from "store/features/Auth/authSlice";

import { routes } from "core/constants";

import { BannerContainer, useStyles } from "./styles";

import { GLOBAL_CONSTANTS } from "../../../constants";
import UpgradeSubscription from "../../MyAccount/UpgradeSubscription";

interface ISaleBanner {
	startDate: string;
	endDate: string;
	copy: string;
	background: string;
}

type SaleBannerProps = Pick<ISaleBanner, "copy">;
const SaleBanner = ({ copy }: SaleBannerProps) => <Box>{decodeURIComponent(atob(copy))}</Box>;

type BannerProps = { bannerMessage: string };
const Banner = ({ bannerMessage }: BannerProps) => {
	const theme = useTheme();
	const classes = useStyles();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const dispatch = useAppDispatch();
	const history = useHistory();
	const trial = useAppSelector(selectIsTrial);
	const { isRenewEnabled } = useAppSelector(selectInactiveSubscription);

	const [modal, setModal] = useState(false);
	const onSubmit = () => {
		setModal(false);
		dispatch(getUserData());
		history.push(`${routes.course.getPath()}?refetch=true`);
	};

	const { closeModal, ...upgradeFormProps } = useUpgradeForm({
		setModal,
		onSubmit,
		dispatch,
		isRenewSubscription: isRenewEnabled
	});

	const saleBannersToShow = useMemo(() => {
		const { SALE_BANNERS } = GLOBAL_CONSTANTS;
		return SALE_BANNERS
			? (JSON.parse(SALE_BANNERS) as Array<ISaleBanner>).filter(({ endDate, startDate }) => {
					const now = new Date();
					return new Date(startDate) < now && new Date(endDate) > now;
			  })
			: [];
	}, []);

	return (
		<>
			<Box
				onClick={() => setModal(true)}
				bgcolor={
					trial && !isRenewEnabled && saleBannersToShow.length && saleBannersToShow[0].background + "! important"
				}
				className={classes.trialNotice}
			>
				<BannerContainer>
					<Typography className={classes.bannerMessage}>
						{trial && !isRenewEnabled ? (
							saleBannersToShow.length ? (
								<SaleBanner {...saleBannersToShow[0]} />
							) : (
								<>
									{!isMobile && "Click"}
									<Link className={classes.manageSubscriptionBtn}>
										{isMobile ? "Trial mode. Upgrade Subscription" : "Here"}
									</Link>
									{!isMobile && "to upgrade your subscription"}
								</>
							)
						) : (
							bannerMessage
						)}
					</Typography>
				</BannerContainer>
				{!trial && (
					<IconButton size="small" className={classes.closeNotice} onClick={() => dispatch(setShowBanner(false))}>
						<Close />
					</IconButton>
				)}
			</Box>
			<Modal open={modal} onClose={closeModal} className={classes.upgradeModal}>
				<UpgradeSubscription {...upgradeFormProps} closeModal={closeModal} />
			</Modal>
		</>
	);
};

export default Banner;
