import React, { FC } from "react";

import { Box, Card, IconButton, Modal, Typography, useTheme } from "@material-ui/core";
import { StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import classnames from "classnames";
import parse from "html-react-parser";

import { generateClassName, useSimpleModalStyles } from "./styles";

export const SimpleModal: FC<{
	[propName: string]: unknown;
	title?: string;
	open: boolean;
	onClose?: () => void;
	footer?: React.ReactElement | null;
	text?: string;
	headerClass?: string;
	icon?: JSX.Element;
	IconSvg?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	extraContent?: JSX.Element;
	modalWidth?: number;
}> = function ({
	title,
	open,
	onClose = () => {},
	footer = <CloseIcon color="disabled" />,
	text,
	IconSvg,
	extraContent,
	headerClass,
	icon,
	modalWidth,
	...props
}) {
	const classes = useSimpleModalStyles();
	const theme = useTheme();
	return (
		<StylesProvider generateClassName={generateClassName}>
			<ThemeProvider theme={theme}>
				<Modal className={classes.modal} disableEnforceFocus disableAutoFocus open={open} {...props}>
					<Box width={modalWidth || 550}>
						<Card className={classes.card}>
							<Box
								className={classnames(classes.modalHeader, {
									[headerClass as string]: headerClass
								})}
							>
								<Typography className={classes.title}>{title}</Typography>
								<IconButton className={classes.closeIcon} onClick={onClose}>
									<CloseIcon color="action" />
								</IconButton>
							</Box>
							{icon && (
								<Box mb={2} display="flex" justifyContent="center" alignItems="center">
									{icon}
								</Box>
							)}
							<Box>
								{IconSvg && (
									<Box display={"flex"} justifyContent={"center"} alignItems={"center"} m={3}>
										<IconSvg />
									</Box>
								)}
								{text && (
									<Box className={classes.subTitleContainer}>
										<Typography className={classes.subTitle}>{parse(text)}</Typography>
									</Box>
								)}
								{extraContent && extraContent}
							</Box>
							{footer && <Box className={classes.modalFooter}>{footer}</Box>}
						</Card>
					</Box>
				</Modal>
			</ThemeProvider>
		</StylesProvider>
	);
};
