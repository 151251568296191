import { Avatar as DefaultAvatar, Menu as DefaultMenu, Typography as DefaultText } from "@material-ui/core";

import styled, { css } from "styled-components";

export const Menu = styled(DefaultMenu)`
	.MuiPaper-root {
		color: white;
		background-color: ${({ theme }) => theme.palette.colors.basic[800]};
	}
`;

export const Container = styled.div`
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	padding: 12px 0 12px 128px;
	width: 100%;
	z-index: 6;

	${({ isMobile, theme }) =>
		isMobile
			? css`
					padding: 6px 0 12px 172px;
					background: ${theme.palette.background.paper};
			  `
			: css`
					background: ${theme.palette.background.paper};
			  `}

	${({ isBanner }) =>
		!isBanner
			? css`
					justify-content: right;
			  `
			: css`
					gap: 10px;
					justify-content: right;
			  `}
	
	.search-icon {
		margin-left: 0;
		padding-left: 10px;
		background-color: ${({ theme }) => theme.palette.background.default};
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}

	.MuiInputBase-root {
		color: ${({ theme }) => theme.palette.text.primary};
	}
`;

export const Avatar = styled(DefaultAvatar)`
	background-color: ${props => props.theme.palette.Avatar.bgColor};
	color: ${props => props.theme.palette.Avatar.textColor};
	cursor: pointer;
`;

export const Actions = styled.div`
	display: flex;
	align-items: center;
	justify-content: right;
	margin-right: 20px;
	gap: 8px;
	width: auto;
	${props => props.theme.breakpoints.down("xs")} {
		margin-right: 10px;
	}
`;

export const Text = styled(DefaultText)`
	margin: 8px 8px 8px 0;
	opacity: 0.87;
	font-size: 13px;
	line-height: 1.23;
	letter-spacing: normal;
	color: ${({ theme }) => theme.palette.text.primary};
`;
