import React from "react";

import { BaseLayout as SharedLayout } from "@remar/shared/dist/layouts/BaseLayout";
import { RootState, useAppSelector } from "store";

import SideNav from "./SideNav";
import { TopNav } from "./TopNav";

const BaseLayout: React.FC = ({ children }) => {
	const { isLoggedIn } = useAppSelector((state: RootState) => state.auth);
	const { subscription } = useAppSelector((state: RootState) => state.auth);

	return (
		<SharedLayout
			isLoggedIn={isLoggedIn}
			topNav={<TopNav />}
			sideBarNav={<SideNav />}
			isTrial={subscription.showBanner}
		>
			{children}
		</SharedLayout>
	);
};

export default BaseLayout;
