import React from "react";

import { Box, Card, IconButton, Modal, Typography, createStyles, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import Button from "../../components/Button";
import { IExtendedTheme } from "../../theme/default";

const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		modal: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},
		card: {
			borderRadius: "5px",
			padding: 0
		},
		cardHeader: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "flex-start",
			padding: 16
		},
		title: {
			fontSize: theme.typography.pxToRem(25),
			fontWeight: 900
		},
		closeIcon: {
			marginTop: "-15px",
			marginRight: "-15px"
		},
		modalFooter: {
			display: "flex",
			justifyContent: "flex-end",
			alignItems: "center",
			gap: "8px",
			padding: 16,
			marginTop: 16,
			borderTop: `1px solid ${theme.palette.divider}`
		}
	})
);

type DialogModalProps = {
	open: boolean;
	title: string;
	cancelBtnText: string;
	successBtnText: string;
	onClose: () => void;
	onSuccess: () => void;
	successBtnDisabled?: boolean;
	loading?: boolean;
	maxWidth?: number;
};

const DialogModal: React.FC<DialogModalProps> = ({
	title,
	onClose,
	children,
	loading,
	onSuccess,
	successBtnText,
	cancelBtnText,
	open,
	maxWidth = 500,
	successBtnDisabled
}) => {
	const classes = useStyles();
	return (
		<Modal className={classes.modal} disableEnforceFocus disableAutoFocus open={open} onClose={onClose}>
			<Box width="100%" maxWidth={maxWidth}>
				<Card className={classes.card}>
					<Box className={classes.cardHeader}>
						<Typography className={classes.title}>{title}</Typography>
						<IconButton className={classes.closeIcon} onClick={onClose}>
							<CloseIcon color="disabled" />
						</IconButton>
					</Box>
					{children}
					<Box className={classes.modalFooter}>
						<Button color="basic" variant="outlined" onClick={onClose}>
							{cancelBtnText}
						</Button>
						<Button
							onClick={onSuccess}
							disabled={loading || successBtnDisabled}
							variant={"filled"}
							color={"primary"}
							loading={loading}
						>
							{successBtnText}
						</Button>
					</Box>
				</Card>
			</Box>
		</Modal>
	);
};

export default DialogModal;
