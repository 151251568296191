import { createStyles } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import styled from "styled-components";

export const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		trialNotice: {
			overflow: "visible",
			cursor: "pointer",
			zIndex: 1,
			flex: 1,
			backgroundColor: theme.palette.primary.main,
			position: "relative",
			justifyContent: "center",
			display: "flex",
			cursor: "pointer",
			"& > div": {
				fontSize: theme.typography.pxToRem(12),
				padding: theme.spacing(1),
				lineHeight: "22px",
				fontWeight: 600
			},
			[theme.breakpoints.down("sm")]: {
				position: "absolute",
				top: 50,
				left: 0,
				right: 0,
				display: "flex",
				justifyContent: "space-between",
				padding: "5px 8px",
				"& > div": {
					padding: 0,
					width: "100%"
				}
			}
		},
		closeNotice: {
			zIndex: 9,
			color: theme.palette.common.white,
			[theme.breakpoints.down("xs")]: {
				position: "unset"
			}
		},
		manageSubscriptionBtn: {
			zIndex: 999,
			padding: "0 0 2px 0",
			fontWeight: "bolder",
			lineHeight: 1.5,
			fontSize: `"${theme.typography.pxToRem(16)}"`,
			minWidth: 0,
			marginInline: "5px",
			color: "inherit",
			[theme.breakpoints.down("sm")]: {
				fontSize: `${theme.typography.pxToRem(14)}`
			}
		},
		bannerMessage: {
			color: theme.palette.common.white,
			[theme.breakpoints.down("xs")]: {
				textAlign: "center",
				fontSize: `"${theme.typography.pxToRem(15)}"`
			}
		},
		upgradeModal: {
			overflow: "auto"
		}
	})
);

export const HelpIcon = styled.div`
	display: flex;
	margin: 0 16px;
	cursor: pointer;
`;

export const BannerContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
