import * as colors from "@remar/shared/dist/colors";

import { DoubleExtendedPalette } from "@remar/shared/dist/models/theme.types";

import defaultColorPalette from "./light_defaultPalette";

const colorPalette: Partial<DoubleExtendedPalette> = {
	type: "light",
	...defaultColorPalette,
	primary: {
		contrastText: colors.basic[100],
		dark: colors.green[500],
		light: colors.green[200],
		main: colors.green[600],
		tabs: {
			border: colors.green[500],
			contrastText: colors.green[800],
			main: colors.green[300]
		}
	},
	secondary: {
		contrastText: colors.green[600],
		dark: colors.green[100],
		light: colors.green[200],
		main: colors.green[200],
		tabs: {
			contrastText: colors.green[800],
			dark: colors.green[500],
			main: colors.green[200],
			border: colors.green[600]
		}
	}
};

export default colorPalette;
