import React from "react";

import { Box, Snackbar, useMediaQuery, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { useAppDispatch, useAppSelector } from "store";
import { NotificationItem, dismiss, selectNotifications } from "store/features/notifications/notifications.slice";

const Notifications = () => {
	const items: NotificationItem[] = useAppSelector(selectNotifications);
	const dispatch = useAppDispatch();
	const theme = useTheme<IExtendedTheme>();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const handleClose = (id: number) => {
		dispatch(dismiss(id));
	};

	return (
		<Box position={isMobile ? "relative" : "fixed"} bottom="0" right="0" width="100%">
			<Box display="flex" flexDirection="column" width="100%">
				{items.map(({ id, message, type }) => (
					<Snackbar
						key={id}
						open
						anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
						style={{ position: isMobile ? "fixed" : "relative", margin: "6px 0", width: isMobile ? "100%" : "auto" }}
					>
						<Alert
							elevation={6}
							variant="filled"
							style={{ width: isMobile ? "100%" : "auto" }}
							onClose={() => handleClose(id)}
							severity={type}
						>
							{message}
						</Alert>
					</Snackbar>
				))}
			</Box>
			{/* <Button onClick={() => snackbar.enqueueSnackbar("=")}>+</Button> */}
		</Box>
	);
};

export default Notifications;
// todo: this is not right place for this component
