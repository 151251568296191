import React, { useEffect } from "react";

import { Badge, ClickAwayListener, IconButton, List, ListItem, Tooltip, useTheme } from "@material-ui/core";
import { Notifications as NotificationsIcon } from "@material-ui/icons";
import useNotificationSocketService from "@remar/shared/dist/services/useNotificationSocketService";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { useAppDispatch, useAppSelector } from "store";
import { addUserNotification, getFullState } from "store/features/Auth/authSlice";
import { UserNotification } from "store/services";

const Notifications = () => {
	const dispatch = useAppDispatch();
	const theme = useTheme<IExtendedTheme>();
	const { userNotifications } = useAppSelector(getFullState);
	const [expanded, setExpanded] = React.useState(false);

	const { subscribeNotifications, terminateSocketConnection } = useNotificationSocketService();

	useEffect(() => {
		subscribeNotifications("notification", (data: UserNotification) => dispatch(addUserNotification(data)));
		return () => {
			terminateSocketConnection(["notification"]);
		};
	}, [dispatch, subscribeNotifications, terminateSocketConnection]);

	return (
		<Tooltip title="Notifications">
			<IconButton size="small" onClick={() => setExpanded(s => !s)}>
				<Badge badgeContent={userNotifications.length} color="primary">
					<NotificationsIcon style={{ width: "20px", height: "20px", fill: "hsl(224,16%,41%)" }} />
				</Badge>
				{expanded && (
					<ClickAwayListener onClickAway={() => setExpanded(false)}>
						<List
							style={{
								position: "absolute",
								width: "400px",
								top: "55px",
								borderRadius: "4px",
								padding: "16px",
								background: theme.palette.background.paper,
								boxShadow: "0 0 12px rgba(27,31,35,.15)"
							}}
						>
							{userNotifications.length === 0 && (
								<ListItem style={{ justifyContent: "center" }}>No notifications yet</ListItem>
							)}
							{userNotifications.map((notification, index) => (
								<ListItem key={index}>{notification.message}</ListItem>
							))}
						</List>
					</ClickAwayListener>
				)}
			</IconButton>
		</Tooltip>
	);
};

export default Notifications;
