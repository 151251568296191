import React, { memo } from "react";

import { IconButton, Tooltip } from "@material-ui/core";
import { HelpRounded } from "@material-ui/icons";

import { TopNav as SharedTopNav } from "@remar/shared/dist/layouts/BaseLayout";
import useAnalyticsEventTracker from "hooks/googleAnalytics";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getFullState, logout, selectUser } from "store/features/Auth/authSlice";

import { routes } from "core/constants";

import Banner from "./Banner";
import Notifications from "./Notifications";

const TopNav = () => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useAppDispatch();

	const { subscription } = useAppSelector(getFullState);
	const userInfo = useAppSelector(selectUser);

	const analytics = useAnalyticsEventTracker("User");

	return (
		<SharedTopNav
			userInfo={userInfo}
			banner={subscription.showBanner && <Banner bannerMessage={subscription.bannerMessage} />}
			accountPath={routes.myAccount.getPath()}
			onLogOut={() => {
				dispatch(logout());
				analytics({ eventName: "logout" });
			}}
			topMenuItems={
				<>
					{/*	<MessageIcon>
						<Icon name="message-linear" height={19} width={22} fill="#2a2e37" />
						</MessageIcon>
					*/}
					<Notifications />
					<Tooltip title="FAQ">
						<IconButton
							size="small"
							onClick={() => {
								if (location.pathname === routes.help.getPath()) history.go(0);
								else history.push(routes.help.getPath());
							}}
						>
							<HelpRounded style={{ width: "20px", height: "20px", fill: "hsl(224,16%,41%)" }} />
						</IconButton>
					</Tooltip>
				</>
			}
		/>
	);
};

export default memo(TopNav);
