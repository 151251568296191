import React from "react";

import { Box, Typography } from "@material-ui/core";
import styled from "styled-components";

import RemarLogoFullSVG from "../../assets/icons/icon-remar-full.svg";

const Wrapper = styled(Box)`
	position: absolute;
	bottom: 24px;
	margin: 0 32px;

	display: flex;
	justify-content: left;
	align-items: end;

	.powered-description {
		font-family: var(--openSans);
		font-size: 13px;
		line-height: 1.86;
		font-weight: 600;
	}

	img {
		margin-bottom: -7px;
	}
`;

const PoweredBlock = () => (
	<Wrapper className="powered-by">
		<Typography className="powered-description">Powered by</Typography>
		<img alt="Powered by ReMar logo" width={63} height={22} src={RemarLogoFullSVG} />
	</Wrapper>
);

export default PoweredBlock;
