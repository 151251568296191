import React, { useState } from "react";

import { Box, Modal, Theme, useTheme } from "@material-ui/core";

import Button from "@remar/shared/dist/components/Button";
import { SimpleModal } from "@remar/shared/dist/components/SimpleModal";

import { useUpgradeForm } from "hooks/useUpgradeForm";

import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { getUserData, selectInactiveSubscription } from "store/features/Auth/authSlice";
import { getSubscriptionInfo, getUserInfo, resumeSubscription } from "store/features/MyAccount/myAccountSlice";

import { routes } from "core/constants";

import UpgradeSubscription from "./UpgradeSubscription";
import { useStyles } from "./styles";

const ResumeAndRenewSubscriptionButton = ({ id, sideEffect = () => {} }: { id: number; sideEffect: () => void }) => {
	const history = useHistory();
	const theme = useTheme<Theme>();
	const classes = useStyles();
	const [modal, setModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useAppDispatch();
	const { isRenewEnabled, subscriptionId } = useAppSelector(selectInactiveSubscription);

	const onSubmit = () => {
		setModal(false);
		dispatch(getSubscriptionInfo());
		dispatch(getUserData());
		dispatch(getUserInfo());
		history.push(`${routes.course.getPath()}?refetch=true`);
	};
	const { closeModal, ...upgradeFormProps } = useUpgradeForm({
		setModal,
		onSubmit,
		dispatch,
		isRenewSubscription: true
	});
	const btnText = isRenewEnabled ? "Renew Subscription" : "Resume Subscription";
	const onClose = () => {
		if (!isLoading) {
			setModal(false);
		}
	};
	const onConfirm = () => {
		setIsLoading(true);
		dispatch(
			resumeSubscription({
				id: isRenewEnabled ? (subscriptionId as number) : id,
				sideEffect: () => {
					sideEffect();
					setModal(false);
					setIsLoading(false);
				}
			})
		);
	};
	return (
		<Box display="flex" justifyContent={"center"}>
			<Button variant={"filled"} color={"primary"} onClick={() => setModal(true)}>
				{btnText}
			</Button>
			{isRenewEnabled ? (
				<Modal open={modal} onClose={closeModal}>
					<UpgradeSubscription {...upgradeFormProps} closeModal={closeModal} />
				</Modal>
			) : (
				<SimpleModal
					theme={theme}
					title={btnText}
					open={modal}
					onClose={onClose}
					text="Are you sure you want to proceed?"
					footer={
						<Box className={classes.buttonContainer}>
							<Button
								loading={isLoading}
								variant={"outlined"}
								color={"basic"}
								size="medium"
								disabled={isLoading}
								onClick={onClose}
							>
								No
							</Button>
							<Button loading={isLoading} variant={"filled"} color={"danger"} size="medium" onClick={() => onConfirm()}>
								Yes
							</Button>
						</Box>
					}
				/>
			)}
		</Box>
	);
};

export default ResumeAndRenewSubscriptionButton;
