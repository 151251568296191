import { useState } from "react";

import { IValidation } from "@remar/shared/dist/components/PasswordMatchIndicator";
import {
	charactersREGX,
	digitSpecialCharacterREGEX,
	lowerCaseREGX,
	passwordREGEX,
	upperCaseREGX
} from "@remar/shared/dist/utils/serviceUtils";

const passwordBoxInit: IValidation = {
	characters: false,
	lowerCase: false,
	upperCase: false,
	numbers: false,
	specialCharacter: false
};

interface IUseValidatePasswordProps {
	validatePassword: (val: string) => void;
	showPasswordErrorBox: boolean;
	passwordErrorBoxState: IValidation;
}
export const useValidatePassword = (): IUseValidatePasswordProps => {
	const [showPasswordErrorBox, setShowPasswordErrorBox] = useState<boolean>(false);
	const [passwordErrorBoxState, setPasswordErrorBoxState] = useState<IValidation>(passwordBoxInit);

	const validatePassword = value => {
		if (value && !passwordREGEX.test(value)) {
			!showPasswordErrorBox && setShowPasswordErrorBox(true);
			setPasswordErrorBoxState({
				characters: charactersREGX.test(value),
				lowerCase: lowerCaseREGX.test(value),
				upperCase: upperCaseREGX.test(value),
				numbers: /\d/.test(value),
				specialCharacter: digitSpecialCharacterREGEX.test(value)
			});
		} else {
			showPasswordErrorBox && setShowPasswordErrorBox(false);
		}
	};

	return {
		validatePassword,
		showPasswordErrorBox,
		passwordErrorBoxState
	};
};
