export const ERROR_MESSAGES = {
	EMAIL_VERIFY_BUTTON_DISPLAY: "Resend the email verfication"
};

export enum UserSubscriptionTypeCategories {
	Course = 1, // eslint-disable-line no-unused-vars
	LocationPackage = 2, // eslint-disable-line no-unused-vars
	LocationPerSeat = 3 // eslint-disable-line no-unused-vars
}

export const USA_COUNTRY_ID = 1;
