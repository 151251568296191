import { Box } from "@material-ui/core";
import styled from "styled-components";

// import DiscountIllustration from "../../assets/images/content-illustrations-discount-blue.svg";

export const LackOfFundsDiscountWrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
	max-width: 350px;
	margin: auto;

	.discount-illustration {
		#Path_1 {
			fill: ${({ theme }) => theme.palette.primary.main};
		}
	}

	.discount-block {
		display: flex;
		flex-direction: column;
		align-content: center;
		align-items: center;
		gap: 16px;
	}

	.discount-title {
		font-family: var(--poppins);
		font-size: 22px;
		font-weight: 700;
		line-height: 1.455;
		color: ${({ theme }) => theme.palette.text.primary};
		opacity: 0.87;
		text-align: center;
	}

	.discount-description {
		font-family: var(--openSans);
		font-size: 15px;
		font-weight: 400;
		line-height: 1.33;
		color: ${({ theme }) => theme.palette.text.primary};
		opacity: 0.87;
		text-align: center;
	}
`;

// todo: uncomment and use as rect-component in case of moving into client app to support multicolors according the theme
// export const StyledDiscountIllustration = styled(DiscountIllustration)`
// 	#Path_1 {
// 		fill: ${({ theme }) => theme.palette.primary.main};
// 	}
// `;
