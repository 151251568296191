import React from "react";

import { Box, Card, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import RemarLogoFullSVG from "@remar/shared/dist/assets/icons/icon-remar-full.svg";
import Button from "@remar/shared/dist/components/Button";
import LackOfFundsDiscount from "@remar/shared/dist/components/LackOfFundsDiscount";

import clsx from "clsx";
import { useAppSelector } from "store";

import { getFullState } from "store/features/Auth/authSlice";

import { ReactComponent as IconCheck } from "assets/icons/icon-checkmark.svg";
import { ReactComponent as IconError } from "assets/icons/icon-circle-cross.svg";

import { useStyles } from "./styles";

const SignUpFollowUpScreen = ({
	success,
	error,
	onClick,
	errorMessage,
	hideActionButtons
}: {
	success: boolean;
	error: boolean;
	onClick: () => void;
	errorMessage?: string;
	hideActionButtons?: boolean;
}) => {
	const theme = useTheme<Theme>();
	const classes = useStyles();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
	const { invitationDetails } = useAppSelector(getFullState);
	const BoxPadding = isMobile ? 0 : 22;

	const locationName = invitationDetails?.invitedBy.locationName;

	return (
		<Card>
			<Box display="flex" justifyContent="center" p={5} pl={BoxPadding} pr={BoxPadding} mt={5}>
				<Box display="flex" justifyContent="center" flexDirection="column">
					<Box display="flex" justifyContent="center" mb={5} mt={4}>
						{success && <IconCheck width={70} height={70} />}
						{error && <IconError width={70} height={70} />}
					</Box>
					<Typography
						className={`${classes.signUpTitleFollowUp} ${success && classes.successTitleFollowUp} ${
							error && classes.errorTitleFollowUp
						}`}
					>
						{success && "Successful Onboarding!"}
						{error && "Something Went Wrong"}
					</Typography>
					<Typography className={classes.signUpSubTitleTextFollowUp}>
						{success && `Welcome to ${locationName ?? "ReMar"}!`}{" "}
						{error && (errorMessage || "We are not able to process your payment. Please try again.")}
					</Typography>
					{error && <LackOfFundsDiscount />}
					{!hideActionButtons && (
						<Box display="flex" justifyContent="center" mt={4}>
							<Button color="primary" variant="filled" onClick={onClick} style={{ width: "90%" }}>
								{success && "Continue"}
								{error && "Try Again"}
							</Button>
						</Box>
					)}
					{locationName && (
						<>
							<Typography className={clsx(classes.signUpSubTitleTextFollowUp, classes.poweredBy)}>
								Powered by
							</Typography>
							<img
								style={{ margin: "auto" }}
								alt="Powered by ReMar logo"
								width={63}
								height={22}
								src={RemarLogoFullSVG}
							/>
						</>
					)}
				</Box>
			</Box>
		</Card>
	);
};

export default SignUpFollowUpScreen;
