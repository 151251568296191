import React from "react";

import { Button, Theme, useTheme } from "@material-ui/core";

import { alpha, makeStyles } from "@material-ui/core/styles";

import classnames from "classnames";

import { SimpleModal } from "../SimpleModal";

export const useStyles = makeStyles({
	keepBtn: {
		backgroundColor: "transparent",
		color: `${props => alpha(props.theme.palette.text.primary, 0.6)}`,
		borderColor: "#6b6e75;",
		borderWidth: "1px",
		borderStyle: "solid",
		marginRight: "10px"
	},
	cancelBtn: {
		backgroundColor: "#46191e",
		color: "#ed5465"
	},
	button: {
		lineHeight: 1.25,
		fontSize: `"${props => props.theme.typography.pxToRem(16)}"`,
		fontWeight: 900,
		padding: "13px 18px 15px"
	}
});
const CommentDeleteModal = ({ modalOpen, setModalOpen, onConfirm, text, isDeleteLoading = false }) => {
	const theme = useTheme<Theme>();
	const classes = useStyles();

	const onClose = () => {
		if (!isDeleteLoading) {
			setModalOpen(false);
		}
	};
	return (
		<SimpleModal
			theme={theme}
			open={modalOpen}
			onClose={onClose}
			text={text}
			footer={
				<>
					<Button className={classnames(classes.keepBtn, classes.button)} size="medium" onClick={onClose}>
						Cancel
					</Button>
					<Button className={classnames(classes.cancelBtn, classes.button)} size="medium" onClick={() => onConfirm()}>
						Delete Comment
					</Button>
				</>
			}
		/>
	);
};

export default CommentDeleteModal;
