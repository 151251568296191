import React, { useEffect, useMemo, useRef, useState } from "react";

import { Box, Typography, useTheme } from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";
import { QuestionTypes } from "@remar/shared/dist/constants";
import { useTimer } from "@remar/shared/dist/hooks/useTimer";
import { UserQuestionAnswerDto } from "@remar/shared/dist/services/userQuestionAnswers/dto";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { getSanitizedHtmlText } from "@remar/shared/dist/utils/serviceUtils";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import {
	createUserQuestionAttempt as createUserLessonAttempt,
	getQuizQuestionAnswers as getLessonQuestionAnswers,
	getquizTestAnswersAlreadyAttemptedCaseStudy as getquizLessonTestAnswersAlreadyAttemptedCaseStudy,
	updateUserQuestionAttempt as updateUserLessonAttempt
} from "store/features/Lesson/lesson.slice";
import {
	createUserQuestionAttempt,
	getFullState,
	getQuizQuestionAnswers as getTestQuestionAnswers,
	getquizTestAnswersAlreadyAttemptedCaseStudy,
	updateUserQuestionAttempt
} from "store/features/QuestionBank/Test/test.slice";

import MultipleChoiceQuestion from "modules/Lesson/MultipleChoiceQuestion";
import SingleChoiceQuestion from "modules/Lesson/SingleChoiceQuestion";
import {
	CaseStudyAlertContainer,
	CaseStudyAlertTitle,
	CaseStudyColumn,
	CaseStudyRow,
	CaseStudyTab,
	CaseStudyTabContent,
	CaseStudyTabs,
	Page,
	Pages,
	StyledError
} from "modules/Lesson/style";

import { CSQuestionTitle, TestQuestionStyledContainer } from "modules/QuestionBank/styles";

import ClozeDropDownQuestion from "./ClozeDropDownQuestion";
import DragAndDropQuestion from "./DragAndDropQuestion";
import DropDownTableQuestion from "./DropDownTableQuestion";
import HighlightTableQuestion from "./HighlightTableQuestion";
import HotspotHighlightQuestion from "./HotspotHighlightQuestion";
import MatrixMultipleChoiceQuestion from "./MatrixMultipleChoiceQuestion";
import MatrixSingleChoiceQuestion from "./MatrixSingleChoiceQuestion";
import MultipleResponseGroupQuestion from "./MultipleResponseGroupQuestion";

const CaseStudyQuestion = ({
	question,
	getCSValidation,
	questionIndex: currentQuestionIndex,
	csRef,
	attemptId = undefined,
	attemptedCSQuestions,
	setCSQuestions,
	loading,
	setCaseStudyCurrentIndex
}) => {
	const {
		data: { tabs: parentTabs },
		caseStudyQuestions: questions = []
	} = question;
	const theme = useTheme<IExtendedTheme>();
	const orderedQuestions = useMemo(() => [...questions].sort((a, b) => a.order - b.order), [questions]);
	const dispatch = useAppDispatch();
	const [activeTab, setActiveTab] = useState(0);
	const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
	const [activeQuestionForChange, setActiveQuestionForChange] = useState(0);
	const { currentTest } = useAppSelector(getFullState);
	const savedLessonAnswers = useAppSelector(getLessonQuestionAnswers);
	const savedTestAnswers = useAppSelector(getTestQuestionAnswers);
	const savedUserAnswers = attemptId ? savedLessonAnswers : savedTestAnswers;
	const refSavedUserAnswers = useRef(savedUserAnswers);
	const currentQuestion = orderedQuestions[activeQuestionIndex];
	const {
		data: { tabs: currentTabs },
		text,
		description
	} = currentQuestion;
	const tabs = currentTabs || parentTabs;
	const forLessonCaseStudy = useAppSelector(getquizLessonTestAnswersAlreadyAttemptedCaseStudy);
	const forTestCaseStudy = useAppSelector(getquizTestAnswersAlreadyAttemptedCaseStudy);
	const quizTestAnswersForRef = attemptId ? forLessonCaseStudy : forTestCaseStudy;
	const [userAnswers, setUserAnswers] = useState<UserQuestionAnswerDto[]>(
		csRef.current[`${activeQuestionIndex}-${question.id}`] ?? []
	);
	const [timeSpentOnLatestQuestion, setTimeSpentOnLatestQuestion] = useState(0);

	const [isAllQuestionAnswered, setIsAllQuestionAnswered] = useState(false);
	const { id: testId } = useParams<{ id: string }>();

	const { seconds, startOrResetTimer } = useTimer();
	const setActiveQuestion = (index: number) => {
		setActiveTab(0);
		setActiveQuestionIndex(index);
	};

	useEffect(() => {
		if (
			quizTestAnswersForRef &&
			csRef.current !== quizTestAnswersForRef &&
			Object.keys(quizTestAnswersForRef).length > 0
		) {
			const currentIndex =
				orderedQuestions.filter(f => Object.keys(quizTestAnswersForRef).includes(f.id.toString())).length - 1;

			for (let i = 0; i < orderedQuestions.length; i++) {
				const answerOpt = quizTestAnswersForRef[orderedQuestions[i].id] ?? [];
				csRef.current[`${i}-${question.id}`] = answerOpt;
			}
			//setTimeout for checkValidate first time when user continue test
			//Rendered component completely then checkValidate
			if (currentIndex < 0) return;
			setTimeout(() => {
				setActiveQuestion(currentIndex);
				checkValidate(true);
			}, 200);
		}
	}, [quizTestAnswersForRef, question.id]);

	useEffect(() => {
		dispatch(
			setCSQuestions({
				questionIndex: currentQuestionIndex,
				id: currentQuestion?.id
			})
		);
		setCaseStudyCurrentIndex(activeQuestionIndex);
	}, [activeQuestionIndex]);

	useEffect(() => {
		if (testId) {
			const questionAttemptTimeSpent =
				(savedUserAnswers && currentQuestion && savedUserAnswers[currentQuestion.id]?.timeSpent) ||
				timeSpentOnLatestQuestion;
			startOrResetTimer(questionAttemptTimeSpent);
		}
		refSavedUserAnswers.current = savedUserAnswers;
	}, [activeQuestionIndex, savedUserAnswers, currentQuestion?.id, testId]);

	useEffect(() => {
		setUserAnswers(csRef.current[`${activeQuestionIndex}-${question.id}`] ?? []);
	}, [activeQuestionIndex, question.id]);

	useEffect(() => {
		if (activeQuestionIndex == 0) return;
		setActiveQuestion(0);
		setActiveQuestionForChange(0);
		setIsAllQuestionAnswered(false);
	}, [question.id]);

	useEffect(() => {
		csRef.current[`${activeQuestionIndex}-${question.id}`] = userAnswers;
		csRef.current["questionId"] = `${activeQuestionIndex}-${question.id}`;
	}, [userAnswers]);

	useEffect(() => {
		getCSValidation(currentQuestion?.typeId, currentQuestion, userAnswers);
		setCaseStudyCurrentIndex(activeQuestionIndex);
	}, [activeQuestionIndex, isAllQuestionAnswered, userAnswers]);

	useEffect(() => {
		if (activeQuestionIndex !== activeQuestionForChange && !loading) {
			setUserAnswers([]);
			setActiveQuestion(activeQuestionForChange);
		}
	}, [loading, activeQuestionForChange]);

	useEffect(() => {
		const currentIndexQuestions = attemptedCSQuestions.filter(ques => ques.questionIndex === currentQuestionIndex);
		currentIndexQuestions.length === 6 && setIsAllQuestionAnswered(true);
		if (currentIndexQuestions.length === 6 && activeQuestionIndex !== 5) {
			getCSValidation(currentQuestion?.typeId, currentQuestion, userAnswers);
		}
		setCaseStudyCurrentIndex(activeQuestionIndex);
	}, [question.typeId, userAnswers, activeQuestionIndex]);

	const sideEffect = (isError = false, index) => {
		!isError && setActiveQuestionForChange(index);
	};

	const handleChangeAnswers = (answers: UserQuestionAnswerDto[], index: number) => {
		if (answers.length > 0) {
			if (refSavedUserAnswers.current && refSavedUserAnswers.current[currentQuestion?.id]) {
				if (currentTest?.id) {
					dispatch(
						updateUserQuestionAttempt({
							questionUserAttemptId: refSavedUserAnswers.current![currentQuestion?.id]?.id,
							userAnswers: answers,
							timeSpent: seconds,
							caseStudySideEffect: (isError = false) => sideEffect(isError, index)
						})
					);
				} else if (attemptId) {
					dispatch(
						updateUserLessonAttempt({
							questionUserAttemptId: refSavedUserAnswers.current![currentQuestion?.id]?.id,
							userAnswers: answers,
							timeSpent: seconds,
							caseStudySideEffect: (isError = false) => sideEffect(isError, index)
						})
					);
				}
			} else {
				setTimeSpentOnLatestQuestion(0);
				if (currentTest?.id) {
					dispatch(
						createUserQuestionAttempt({
							userCustomTestId: currentTest.id,
							questionId: question.id,
							subQuestionId: currentQuestion.id,
							userAnswers: answers,
							timeSpent: seconds,
							caseStudySideEffect: (isError = false) => sideEffect(isError, index)
						})
					);
				} else if (attemptId) {
					dispatch(
						createUserLessonAttempt({
							userLessonAttemptId: attemptId,
							questionId: question.id,
							subQuestionId: currentQuestion.id,
							userAnswers: answers,
							timeSpent: seconds,
							caseStudySideEffect: (isError = false) => sideEffect(isError, index)
						})
					);
				}
			}
		}
	};

	const handleOnChange = answers => {
		if (answers.length > 0) {
			setUserAnswers(answers);
		}
	};

	const getQuestion = question => {
		const uniqueIdentifier = `case-study-question-${question.id}`;

		switch (question.typeId) {
			case QuestionTypes.MatrixSingleChoice:
				return (
					<MatrixSingleChoiceQuestion
						question={question}
						onChange={answers => {
							handleOnChange(answers);
						}}
						userAnswers={userAnswers}
						key={uniqueIdentifier}
					/>
				);
			case QuestionTypes.MultipleChoiceSN:
			case QuestionTypes.MultipleChoiceSATA:
				return (
					<MultipleChoiceQuestion
						question={question}
						onChange={answers => {
							handleOnChange(answers);
						}}
						userAnswers={userAnswers}
						key={uniqueIdentifier}
					/>
				);
			case QuestionTypes.MultipleResponseGroup:
				return (
					<MultipleResponseGroupQuestion
						question={question}
						onChange={answers => {
							handleOnChange(answers);
						}}
						userAnswers={userAnswers}
						key={uniqueIdentifier}
					/>
				);
			case QuestionTypes.DragAndDrop:
			case QuestionTypes.RationalScoringDragAndDrop:
				return (
					<DragAndDropQuestion
						question={question}
						onChange={answers => {
							handleOnChange(answers);
						}}
						answersRef={csRef}
						userAnswers={userAnswers}
						key={uniqueIdentifier}
					/>
				);
			case QuestionTypes.DropDownTable:
				return (
					<DropDownTableQuestion
						question={question}
						onChange={answers => {
							handleOnChange(answers);
						}}
						userAnswers={userAnswers}
						key={uniqueIdentifier}
					/>
				);
			case QuestionTypes.MatrixMultipleChoice:
				return (
					<MatrixMultipleChoiceQuestion
						question={question}
						onChange={answers => {
							handleOnChange(answers);
						}}
						userAnswers={userAnswers}
						key={uniqueIdentifier}
					/>
				);
			case QuestionTypes.ClozeDropDown:
			case QuestionTypes.RationalScoringDropDown:
				return (
					<ClozeDropDownQuestion
						question={question}
						onChange={answers => {
							handleOnChange(answers);
						}}
						userAnswers={userAnswers}
						key={uniqueIdentifier}
					/>
				);
			case QuestionTypes.SingleChoice:
				return (
					<SingleChoiceQuestion
						question={question}
						onChange={answers => {
							handleOnChange(answers);
						}}
						userAnswers={userAnswers}
						key={uniqueIdentifier}
					/>
				);
			case QuestionTypes.HotspotHighlight:
				return (
					<HotspotHighlightQuestion
						question={question}
						onChange={answers => {
							handleOnChange(answers);
						}}
						key={uniqueIdentifier}
						userAnswers={userAnswers}
					/>
				);
			case QuestionTypes.HighlightTable:
				return (
					<HighlightTableQuestion
						question={question}
						onChange={answers => {
							handleOnChange(answers);
						}}
						key={uniqueIdentifier}
						userAnswers={userAnswers}
					/>
				);
			default:
				return null;
		}
	};

	const checkAllSelectionHandle = (question, options, scAnswer) => {
		const {
			data: { groups }
		} = question;
		const filteredGroups = groups.filter(group => group[options]);
		return !(filteredGroups.length === scAnswer.length);
	};

	const getQuestionTypeValidation = (id, csQuestion, scAnswer) => {
		switch (id) {
			case QuestionTypes.ClozeDropDown:
			case QuestionTypes.RationalScoringDropDown:
			case QuestionTypes.DropDownTable:
				return checkAllSelectionHandle(csQuestion, "answerOptions", scAnswer);
			case QuestionTypes.DragAndDrop:
			case QuestionTypes.RationalScoringDragAndDrop:
			case QuestionTypes.MatrixSingleChoice:
				return checkAllSelectionHandle(csQuestion, "selectedAnswerOptions", scAnswer);
			case QuestionTypes.MatrixMultipleChoice: {
				const isAllColumnsSelected = csQuestion.data.groups.every(group => {
					return scAnswer.some(answer => answer.groupId === group.id);
				});
				return !isAllColumnsSelected;
			}
			case QuestionTypes.HotspotHighlight:
				return scAnswer.length <= 0;
			case QuestionTypes.MultipleResponseGroup:
			case QuestionTypes.HighlightTable:
				const isAllSelected = csQuestion.data.groups.every(group =>
					scAnswer.some(answer => answer.groupId === group.id)
				);
				return !isAllSelected;
			default:
				return scAnswer.length === 0;
		}
	};

	const checkValidate = (ignoreInternalValidation = false) => {
		let disabled = false;
		orderedQuestions.forEach((qu, index) => {
			const findAnswer = csRef.current[`${index}-${question.id}`] ?? quizTestAnswersForRef[qu.id] ?? [];
			disabled = getQuestionTypeValidation(qu.typeId, qu, findAnswer);
			if (ignoreInternalValidation || disabled) {
				getCSValidation(qu.typeId, qu, findAnswer);
				return;
			}
		});
	};
	checkValidate();
	const handleChangeQuestion = questionIndex => {
		const isDisabled = getQuestionTypeValidation(currentQuestion.typeId, currentQuestion, userAnswers);
		if (isDisabled) {
			return;
		} else {
			!loading && handleChangeAnswers(userAnswers, questionIndex);
		}
	};

	return (
		<>
			<CaseStudyRow>
				<CaseStudyColumn>
					<CaseStudyTabs>
						{tabs.map(({ title, id }, tabIndex) => (
							<CaseStudyTab key={`tab-${id}`} active={tabIndex === activeTab} onClick={() => setActiveTab(tabIndex)}>
								{title}
							</CaseStudyTab>
						))}
					</CaseStudyTabs>
					{activeTab !== undefined && (
						<CaseStudyTabContent dangerouslySetInnerHTML={{ __html: tabs[activeTab]?.text }}></CaseStudyTabContent>
					)}
				</CaseStudyColumn>
				<CaseStudyColumn>
					<TestQuestionStyledContainer>
						<CSQuestionTitle>
							Case Study Screen {activeQuestionIndex + 1} of {orderedQuestions.length}
						</CSQuestionTitle>
					</TestQuestionStyledContainer>
					<Typography style={{ color: theme.palette.colors.basic[1100], fontWeight: "600" }}>
						{getSanitizedHtmlText(text)}
					</Typography>
					<Box style={{ color: theme.palette.colors.basic[600] }} dangerouslySetInnerHTML={{ __html: description }} />
					{currentQuestion && getQuestion(currentQuestion)}
				</CaseStudyColumn>
			</CaseStudyRow>
			<Pages>
				{loading && <CircularProgress size={30} />}
				<Page isArrow onClick={() => handleChangeQuestion(Math.max(activeQuestionIndex - 1, 0))}>
					&lt;
				</Page>
				{orderedQuestions?.map((_, questionIndex) => (
					<Page
						active={activeQuestionIndex === questionIndex}
						key={`question-${questionIndex}`}
						onClick={() => {
							handleChangeQuestion(questionIndex);
						}}
					>
						{questionIndex + 1}
					</Page>
				))}
				<Page
					isArrow
					onClick={() => handleChangeQuestion(Math.min(activeQuestionIndex + 1, orderedQuestions.length - 1))}
				>
					&gt;
				</Page>
			</Pages>
		</>
	);
};

export const Alert = () => (
	<CaseStudyAlertContainer>
		<CaseStudyAlertTitle>
			<StyledError />
			Case Study Questionaire
		</CaseStudyAlertTitle>
		This is a case study question… you need to complete 6 questions before moving forward.
	</CaseStudyAlertContainer>
);

export default CaseStudyQuestion;
