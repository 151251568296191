import React, { useEffect, useState } from "react";

import { Theme, useTheme } from "@material-ui/core";
import Button from "@remar/shared/dist/components/Button";

import { SimpleModal } from "@remar/shared/dist/components/SimpleModal";

import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { deleteAccount, selectFullState } from "store/features/MyAccount/myAccountSlice";

import { routes } from "core/constants";

import { BodyText, ConfirmPasswordField } from "./styles";

const DeleteAccountConfirmation = ({ showDeleteModal, setShowDeleteModal }) => {
	const theme = useTheme<Theme>();
	const [isLoading, setIsLoading] = useState(false);
	const [password, setPassword] = useState("");
	const accountState = useAppSelector(selectFullState);
	const history = useHistory();

	const signInRoute = routes.signIn.getPath();

	const { accountDeleted, isDeleteError } = accountState;

	useEffect(() => {
		if (!isDeleteError && accountDeleted) {
			setShowDeleteModal(false);

			history.push(signInRoute);
		}
	}, [accountDeleted, isDeleteError]);

	const dispatch = useAppDispatch();
	const onClose = () => {
		if (!isLoading) {
			setShowDeleteModal(false);
		}
	};

	const onConfirm = () => {
		setIsLoading(true);
		dispatch(
			deleteAccount({
				password,
				sideEffect: () => {
					setIsLoading(false);
				}
			})
		);
	};

	return (
		<SimpleModal
			theme={theme}
			title="Delete Account"
			open={showDeleteModal}
			onClose={onClose}
			text={""}
			extraContent={
				<>
					<BodyText>Please enter your password to delete your account.</BodyText>
					<ConfirmPasswordField onChange={e => setPassword(e.target.value)} type="password" placeholder="Password" />
				</>
			}
			footer={
				<>
					<Button
						variant="outlined"
						color="basic"
						size="medium"
						disabled={isLoading}
						onClick={onClose}
						loading={isLoading}
					>
						No, Cancel
					</Button>
					<Button
						disabled={isLoading || !password}
						size="medium"
						variant="filled"
						color={"danger"}
						loading={isLoading}
						onClick={() => onConfirm()}
					>
						Yes, Delete Account
					</Button>
				</>
			}
		/>
	);
};

export default DeleteAccountConfirmation;
